import { i18n } from '@wefox/i18n';

import { BrokerDTO, PersonDTO, ProductDTO } from '@wefox-scheduling/shared/interfaces';
import { productsService } from '@wefox-scheduling/shared/services';

import { TimeSlotModel } from '../../time-slots-section/model/time-slot.model';
import { BrokerAppointmentBodyRequest } from '../interfaces/broker-appointment-body-request';
import { getDateHourFromTimeSlot } from '../utils/date-and-time';

import { calendarEventDataService } from './calendar-event-data.service';

interface BrokerAppointmentParams {
  comment: string;
  selectedDate: Date;
  selectedTimeSlot: TimeSlotModel;
  broker: BrokerDTO;
  user: PersonDTO | null;
  selectedProducts: ProductDTO[];
}

class ConsultationReviewService {
  public requestBrokerAppointment(params: BrokerAppointmentParams): Promise<void> {
    const { id } = params.broker;
    const data = this._getBrokerAppointmentData(params);

    return calendarEventDataService.postBrokerAppointment(id, data);
  }

  private _getBrokerAppointmentData(data: BrokerAppointmentParams): BrokerAppointmentBodyRequest {
    const startTime = getDateHourFromTimeSlot(data.selectedDate, data.selectedTimeSlot.value.from);
    const endTime = getDateHourFromTimeSlot(data.selectedDate, data.selectedTimeSlot.value.to);

    return {
      summary: `${data.broker.first_name}/${data.user?.first_name}`,
      description: this._getFormattedDescription(data.comment, data.selectedProducts),
      start_time: startTime,
      end_time: endTime,
      attendees: [
        {
          email: data.user?.email || '',
          display_name: data.user?.first_name || '',
        },
      ],
    };
  }

  private _getFormattedDescription(comment: string, selectedProducts: ProductDTO[]): string {
    let description = '';

    if (selectedProducts?.length) {
      const productTitle = `<b>${i18n.getTranslation('CONSULTATION_EVENT_product_title')}</b>`;
      const translatedSelectedProducts = productsService.getTranslatedProducstNames(selectedProducts);

      description = description.concat(`${productTitle}\n${translatedSelectedProducts}\n`);
    }

    if (comment) {
      const commentTitle = `<b>${i18n.getTranslation('CONSULTATION_EVENT_comment_title')}</b>`;

      description = description.concat(`${commentTitle}\n${comment}\n`);
    }

    return description;
  }
}

export const consultationReviewService = new ConsultationReviewService();
