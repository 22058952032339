import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  hostSelector?: string;
}

export const Portal = ({
  children,
  hostSelector = '[id$="@wefox/ma-scheduling"]',
}: React.PropsWithChildren<Props>): JSX.Element | null => {
  const el = document.querySelector(hostSelector);

  if (el === null) {
    return null;
  }

  return ReactDOM.createPortal(children, el);
};
