import React from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  title: string;
  subTitle?: string;
}

export const PageHeader = ({ title, subTitle }: Props): JSX.Element => {
  return (
    <div className="u-mb-6 u-mt-12">
      <div className="u-ty-heading-5 u-mb-2">{i18n.getTranslation(title)}</div>
      {subTitle && <p className="u-ty-body">{i18n.getTranslation(subTitle)}</p>}
    </div>
  );
};
