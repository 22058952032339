import { i18n } from '@wefox/i18n';

import { EVENTS } from './toolbar-events';

export function logoConfig(): Record<string, unknown> {
  return {
    type: 'logo',
    activateOnScroll: true,
    heading: {
      type: 'logo',
      payload: {
        href: '/',
        source: '/assets/wefox.svg',
      },
    },
  };
}

export function backNavigationConfig(title = ''): Record<string, unknown> {
  return {
    type: 'default',
    activateOnScroll: true,
    navigation: {
      icon: 'arrow-left',
      name: 'Navigation',
      eventName: EVENTS.backNavigationClick,
    },
    heading: {
      type: 'string',
      payload: {
        value: i18n.getTranslation(title),
      },
    },
    actions: [],
  };
}

export function titleOnlyConfig(title: string): Record<string, unknown> {
  return {
    type: 'default',
    activateOnScroll: true,
    navigation: {},
    heading: {
      type: 'string',
      payload: {
        value: i18n.getTranslation(title),
      },
    },
    actions: [],
  };
}

export function backNavigationWithCloseActionConfig(title = ''): Record<string, unknown> {
  return {
    type: 'default',
    activateOnScroll: true,
    navigation: {
      icon: 'arrow-left',
      name: 'Navigation',
      eventName: EVENTS.backNavigationClick,
    },
    heading: {
      type: 'string',
      payload: {
        value: i18n.getTranslation(title),
      },
    },
    actions: [
      {
        icon: 'x',
        name: 'close',
        eventName: 'toolbarCloseActionClick',
      },
    ],
  };
}

export function hiddenToolbar(): Record<string, unknown> {
  return {
    type: 'default',
    activateOnScroll: true,
    heading: {
      type: 'text',
      payload: {
        value: '',
      },
      style: {
        alignment: 'start',
        variant: 'medium',
        shrink: true,
      },
    },
    actions: [],
  };
}
