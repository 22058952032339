import React from 'react';

import { Feedback, Portal } from '@wefox-scheduling/shared/components';
import { useSetToolbar } from '@wefox-scheduling/shared/hooks';
import { hiddenToolbar } from '@wefox-scheduling/shared/utils';

interface Props {
  type: 'empty' | 'success' | 'error' | 'loading';
  title: string;
  description: string;
  primaryCtaLabel: string;
  onPrimaryCtaClick?: () => void;
}

export const PageFeedback = ({ type, title, description, primaryCtaLabel, onPrimaryCtaClick }: Props): JSX.Element => {
  useSetToolbar(hiddenToolbar());

  return (
    <Portal>
      <div className="o-container o-container--full-height">
        <div className="o-grid o-grid--centered">
          <div className="o-col-mobile-4 o-col-tablet-4 o-col-start-tablet-2 o-col-laptop-6 o-col-start-laptop-4">
            {type === 'success' && (
              <Feedback
                data-testid="page-success-feedback"
                type="success"
                title={title}
                description={description}
                primaryCtaLabel={primaryCtaLabel}
                onPrimaryCtaClick={onPrimaryCtaClick}
              ></Feedback>
            )}
            {type === 'error' && (
              <Feedback
                data-testid="page-error-feedback"
                type="danger"
                title={title}
                description={description}
                primaryCtaLabel={primaryCtaLabel}
                onPrimaryCtaClick={onPrimaryCtaClick}
              ></Feedback>
            )}
            {type === 'loading' && (
              <Feedback
                data-testid="page-loading-feedback"
                type="loader"
                title={title}
                description={description}
              ></Feedback>
            )}
            {type === 'empty' && (
              <Feedback
                data-testid="page-empty-feedback"
                type="info"
                title={title}
                description={description}
              ></Feedback>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};
