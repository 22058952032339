import React from 'react';

import { PageHeader } from '@wefox-scheduling/shared/components';

import { BrokerInformationController } from '../broker-information/broker-information.controller';
import { ContactFormController } from '../contact-form/contact-form.controller';
import { useContactPageStateUI } from '../contact-page.state-ui';
import { ContactErrorScreenController } from '../error-screen/contact-error-screen.controller';
import { ContactLoadingScreenController } from '../loading-screen/contact-loading-screen.controller';
import { ContactSuccessScreenController } from '../sucess-screen/contact-success-screen.controller';

export const Layout = (): JSX.Element => {
  const { view } = useContactPageStateUI();

  if (view === 'success') {
    return <ContactSuccessScreenController />;
  }

  if (view === 'error') {
    return <ContactErrorScreenController />;
  }

  if (view === 'loading') {
    return <ContactLoadingScreenController />;
  }

  return (
    <div className="o-container">
      <div className="o-grid">
        <div className="o-col-mobile-4 o-col-tablet-6 o-col-laptop-6 o-col-start-laptop-4">
          <PageHeader title="RA_GET_ADVICE_title" subTitle="RA_GET_ADVICE_subtitle" />
          <BrokerInformationController />
          <ContactFormController />
        </div>
      </div>
    </div>
  );
};
