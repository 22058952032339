import { WeekAvailability } from '../interfaces/calendar-week-availability';

import { brokerAvailabilityDataService } from './broker-availability-data.service';

export class BrokerAvailabilityService {
  public async getData(brokerId: string): Promise<number[] | null> {
    try {
      const availability = await brokerAvailabilityDataService.getBrokerAvailability(brokerId);

      if (!this._hasAvailability(availability.calendar_config.weekday)) {
        return null;
      }

      const { weekday } = availability.calendar_config;

      return this._getDisabledWeekDays(weekday);
    } catch (_) {
      return null;
    }
  }

  private _getDisabledWeekDays(weekdays: WeekAvailability): number[] {
    return Object.entries(weekdays)
      .filter(([_, value]) => !value)
      .map(([key, _]) => parseInt(key, 10));
  }

  private _hasAvailability(weekdays: WeekAvailability): boolean {
    return Object.values(weekdays).some(Boolean);
  }
}

export const brokerAvailabilityService = new BrokerAvailabilityService();
