import cn from 'classnames';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

import { i18n } from '@wefox/i18n';

import { phonePrefix } from '../consts/phone-prefixes.const';
import { FormFieldProperties } from '../interfaces/form-field.interface';

import './phone-number-field.component.scss';

export const PhoneNumberField = ({ errors, id }: FormFieldProperties): JSX.Element => {
  const dropdownRef = useRef<HTMLElement>(null);
  const { setFieldValue, getFieldProps } = useFormikContext<{ [key: string]: string[] }>();
  const { value: phonePrefixValue } = getFieldProps('phonePrefix');
  const { value: phoneNumberValue } = getFieldProps(id);

  const handleOnChange = (evt: Event): void => {
    const customEvt = evt as CustomEvent<string>;
    const newValue = customEvt.detail;

    setFieldValue('phonePrefix', newValue);
  };

  useEffect(() => {
    const wefoxDropdown = dropdownRef.current;

    wefoxDropdown?.addEventListener('wefoxDropdownOnChange', handleOnChange);

    return () => {
      wefoxDropdown?.removeEventListener('wefoxDropdownOnChange', handleOnChange);
    };
  });

  return (
    <div className="form-group u-mb-3">
      <div>
        <label className="form-label" htmlFor={id}>
          {i18n.getTranslation(`RA_GET_ADVICE_field_${id}`)}
        </label>
        <div className="row g-small">
          <div className="u-d-flex">
            <div className="u-position-relative border rounded-1 border-neutral u-px-3 bg-white phone-number">
              <wefox-dropdown
                ref={dropdownRef}
                id={`${id}Prefix`}
                open-to="left"
                label={phonePrefixValue && `+${phonePrefixValue}`}
                show-selected
              >
                {phonePrefix.map((prefixData) => (
                  <wefox-dropdown-option
                    key={prefixData.code}
                    value={prefixData.prefix}
                    icon={prefixData.code}
                    icon-set="flags"
                  >
                    +{prefixData.prefix}
                  </wefox-dropdown-option>
                ))}
              </wefox-dropdown>
            </div>
            <Field
              type="text"
              className={cn('form-control u-ml-3', errors[id] && 'is-invalid')}
              placeholder={i18n.getTranslation(`RA_GET_ADVICE_placeholder_${id}`)}
              id={id}
              value={phoneNumberValue || ''}
            />
          </div>
          <div className={cn('invalid-feedback', errors[id] && 'u-d-block')}>
            {i18n.getTranslation(errors[id] as string)}
          </div>
        </div>
      </div>
    </div>
  );
};
