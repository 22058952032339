import { FormikConfig, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';

import { ContactFormValues } from '../interfaces/contact-values.interface';

interface ContactFormConfigParams {
  initialValues: ContactFormValues;
  onSubmit: (values: ContactFormValues) => Promise<void>;
}

export const useContactFormConfig = ({
  initialValues,
  onSubmit,
}: ContactFormConfigParams): FormikConfig<FormikValues> => {
  const validationSchema: FormikConfig<FormikValues>['validationSchema'] = Yup.object().shape({
    contactPreferences: Yup.array().min(1, 'ERROR_empty_field'),
    phonePrefix: Yup.string(),
    phone: Yup.string()
      .min(6, 'ERROR_phone_number_min_characters')
      .matches(/^[0-9]*$/, 'ERROR_phone_number_only_numbers')
      .required('ERROR_empty_field'),
  });

  const submitHandler = (
    values: FormikValues,
    { setSubmitting }: Pick<FormikHelpers<FormikValues>, 'setSubmitting'>
  ): void => {
    onSubmit(values as ContactFormValues);

    setSubmitting(false);
  };

  return {
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: true,
    initialErrors: {},
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema,
  };
};
