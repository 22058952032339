import React, { useCallback, useEffect, useRef } from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  brokerFullName: string | null;
  email?: string;
  comment: string;
  dateAndTime: string;
  onInputChange: (value: string) => void;
}
export const InformationContent = ({
  brokerFullName,
  email,
  comment,
  dateAndTime,
  onInputChange,
}: Props): JSX.Element | null => {
  const inputRef = useRef<HTMLElement>(null);
  const handleInputChange = useCallback(
    (evt: Event) => {
      const { detail } = evt as CustomEvent;

      onInputChange(detail);
    },
    [onInputChange]
  );

  useEffect(() => {
    const inputEl = inputRef.current as HTMLElement;

    inputEl?.addEventListener('asteroidInputOnInput', handleInputChange);

    return () => {
      inputEl?.addEventListener('asteroidInputOnInput', handleInputChange);
    };
  }, [handleInputChange]);

  return (
    <>
      <asteroid-list>
        <asteroid-group-row
          data-testid="broker-name"
          value={brokerFullName}
          label={i18n.getTranslation('REVIEW_CONSULTATION_name')}
        />
        <asteroid-group-row
          data-testid="selected-time"
          value={dateAndTime}
          label={i18n.getTranslation('REVIEW_CONSULTATION_date')}
        />
        {email ? (
          <asteroid-group-row
            data-testid="email"
            value={email}
            label={i18n.getTranslation('REVIEW_CONSULTATION_mail')}
          />
        ) : (
          <asteroid-skeleton-loader class="u-my-4" width="100%">
            <svg>
              <rect x="0.866211" y="0.0800781" width="350" height="40" rx="4" fill="#EBEAE8" />
            </svg>
          </asteroid-skeleton-loader>
        )}
      </asteroid-list>
      <asteroid-alert
        data-testid="consultation-location"
        class="u-d-block u-mb-8 u-mt-5"
        description={i18n.getTranslation('REVIEW_CONSULTATION_location')}
        type="info"
        has-type-icon="true"
      ></asteroid-alert>
      <h4>{i18n.getTranslation('REVIEW_CONSULTATION_comment')}</h4>
      <div className="u-mb-8">
        <asteroid-input
          ref={inputRef}
          placeholder={i18n.getTranslation('REVIEW_CONSULTATION_comment_placeholder')}
          value={comment}
        ></asteroid-input>
      </div>
    </>
  );
};
