import React from 'react';

import { i18n } from '@wefox/i18n';

import { CalendarSectionController } from '../calendar-section/calendar-section.controller';
import { TimeSlotsSectionController } from '../time-slots-section/time-slots-section.controller';

interface ConsultationScreenDesktopProps {
  isContinueButtonDisabled: boolean;
  onContinueClick: () => void;
  onBackClick: () => void;
}
export const ConsultationScreenDesktop = ({
  isContinueButtonDisabled,
  onContinueClick,
  onBackClick,
}: ConsultationScreenDesktopProps): JSX.Element => {
  return (
    <>
      <asteroid-card>
        <div className="d-grid cols-2 u-gapy-xxxl">
          <div>
            <CalendarSectionController />
          </div>
          <div className="u-mt-7">
            <TimeSlotsSectionController />
          </div>
        </div>
      </asteroid-card>
      <div className="u-mb-10 u-d-flex u-justify-content-between u-pt-8">
        <asteroid-button data-testid="back-button" type="submit" onClick={onBackClick}>
          <asteroid-icon icon="arrow-left"></asteroid-icon>
        </asteroid-button>
        <asteroid-button kind="primary" type="submit" onClick={onContinueClick} disabled={isContinueButtonDisabled}>
          {i18n.getTranslation('GLOBAL_continue')}
        </asteroid-button>
      </div>
    </>
  );
};
