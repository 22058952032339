import { i18n } from '@wefox/i18n';

import { BrokerDTO } from '@wefox-scheduling/shared/interfaces';

class BrokerService {
  public getBrokerTitle(broker: BrokerDTO | null | undefined): string {
    if (!broker || !broker.id) {
      return i18n.getTranslation('RA_GET_ADVICE_NO_BROKER_TITLE');
    }

    return broker.salutation
      ? `${broker.salutation} ${broker.first_name} ${broker.last_name}`
      : `${broker.first_name} ${broker.last_name}`;
  }
}

export const brokerService = new BrokerService();
