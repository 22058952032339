import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TimeSlotModel } from '../model/time-slot.model';

interface Props {
  timeSlots?: TimeSlotModel[];
  onChange: (selectedTimeSlot: TimeSlotModel) => void;
}

export const TimeSlotsAvailability = ({ timeSlots, onChange }: Props): JSX.Element => {
  const selectionBoxRefs = useRef<HTMLElement[]>([]);
  const [selectedElement, setSelectedElement] = useState<TimeSlotModel>();

  const handleClick = useCallback(
    (evt: Event): void => {
      if (timeSlots) {
        const target = evt.target as HTMLElement;
        const selectedItemIndex = parseInt(target.dataset.index || '', 10);
        const timeSlot = timeSlots[selectedItemIndex];

        setSelectedElement(timeSlot);
        onChange(timeSlot);
      }
    },
    [onChange, timeSlots]
  );

  useEffect(() => {
    const asteroidSelection = selectionBoxRefs.current;

    asteroidSelection.forEach((selection) => {
      selection?.addEventListener('wefoxRadioOnClick', handleClick);
    });

    return (): void => {
      asteroidSelection.forEach((selection) => {
        selection?.removeEventListener('wefoxRadioOnClick', handleClick);
      });
    };
  }, [handleClick, timeSlots]);

  return (
    <div className="grid cols-2 g-medium">
      {timeSlots?.map((timeSlot, i) => (
        <asteroid-selection-box
          key={i}
          ref={(ref: HTMLElement): void => {
            selectionBoxRefs.current[i] = ref;
          }}
          checked={selectedElement?.value === timeSlot.value}
          name="field-form-radio"
          value={timeSlot.value}
          type="radio"
          data-index={i}
          data-testid={`selection-box-${i}`}
        >
          <div className="u-ty-body-sb">{timeSlot.displayedValue}</div>
        </asteroid-selection-box>
      ))}
    </div>
  );
};
