export const PREFERRED_APPOINTMENTS = [
  {
    title: {
      key: 'RA_GET_ADVICE_field_contactPreference_morning_title',
      fallback: 'Morgen',
    },
    subtitle: {
      key: 'RA_GET_ADVICE_field_contactPreference_morning',
      fallback: 'Morgen',
    },
  },
  {
    title: {
      key: 'RA_GET_ADVICE_field_contactPreference_afternoon_title',
      fallback: 'Nachmittag',
    },
    subtitle: {
      key: 'RA_GET_ADVICE_field_contactPreference_afternoon',
      fallback: 'Nachmittag',
    },
  },
  {
    title: {
      key: 'RA_GET_ADVICE_field_contactPreference_evening_title',
      fallback: 'Abend',
    },
    subtitle: {
      key: 'RA_GET_ADVICE_field_contactPreference_evening',
      fallback: 'Abend',
    },
  },
];
