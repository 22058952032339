import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { FeatureFlagGuard, PrivateRouteGuard } from '@wefox-scheduling/guards';
import { CalendarPage, ContactPage } from '@wefox-scheduling/pages';
import { featureFlagService } from '@wefox-scheduling/shared/services';

export const RoutesComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="scheduling/calendar"
        element={
          <FeatureFlagGuard
            featureFlags={[featureFlagService.contractConsultation, featureFlagService.riskConsultation]}
          >
            <PrivateRouteGuard>
              <CalendarPage />
            </PrivateRouteGuard>
          </FeatureFlagGuard>
        }
      ></Route>
      <Route
        path="scheduling/contact"
        element={
          <FeatureFlagGuard
            featureFlags={[featureFlagService.contractConsultation, featureFlagService.riskConsultation]}
          >
            <PrivateRouteGuard>
              <ContactPage />
            </PrivateRouteGuard>
          </FeatureFlagGuard>
        }
      ></Route>
    </Routes>
  );
};
