import { authModule } from '@wefox/auth';
import { http } from '@wefox/http';
import { i18n } from '@wefox/i18n';

import { wefoxConfigService } from '@wefox-scheduling/shared/services';
import { getCountryCode } from '@wefox-scheduling/shared/utils';

class HttpRequestService {
  public get<Response>(url: string, options?: RequestInit): Promise<Response> {
    return http.get(`${wefoxConfigService.getGateWay()}${url}`, this._mergePostOptions(options));
  }

  public patch<Response>(url: string, options: RequestInit): Promise<Response> {
    return http.patch<Response>(`${wefoxConfigService.getGateWay()}${url}`, this._mergePostOptions(options));
  }

  public post<Response>(url: string, options: RequestInit): Promise<Response> {
    return http.post<Response>(`${wefoxConfigService.getGateWay()}${url}`, this._mergePostOptions(options));
  }

  private _mergePostOptions(options: RequestInit = {}): RequestInit {
    const languageCode = i18n.getLanguageFromCurrentLocale();

    const baseHeaders: HeadersInit = {
      'X-market': getCountryCode(),
      'accept-language': languageCode,
      'X-Language': languageCode,
      Authorization: `Bearer ${authModule.getInstance().token}`,
      'Content-Type': 'application/json',
      'X-Profile': wefoxConfigService.getXProfileHeader(),
    };

    return {
      ...options,
      headers: {
        ...baseHeaders,
        ...options.headers,
      },
    };
  }
}

export const httpRequestService = new HttpRequestService();
