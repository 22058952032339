import { i18n } from '@wefox/i18n';

import { ProductDTO } from '@wefox-scheduling/shared/interfaces';

export class ProductsService {
  public getTranslatedProducstNames = (products: ProductDTO[]): string => {
    const translatedProducts = this.getTranslatedProducts(products);

    return translatedProducts.map((product) => product.name).join(', ');
  };

  public getTranslatedProducts = (products: ProductDTO[]): ProductDTO[] => {
    return products.map((product: ProductDTO) => ({
      ...product,
      name: i18n.getTranslation(`RA_PRODUCT_${product.code}_name`),
    }));
  };
}

export const productsService = new ProductsService();
