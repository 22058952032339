import React, { Dispatch, createContext, useContext, useReducer } from 'react';

interface PageStateUI {
  view: 'consultation' | 'review';
}

interface SetCurrentViewAction {
  type: 'set-current-view';
  payload: PageStateUI['view'];
}

type PageStateUIActions = SetCurrentViewAction;

const initialState: PageStateUI = {
  view: 'consultation',
};

const reducer = (state: PageStateUI, action: PageStateUIActions): PageStateUI => {
  switch (action.type) {
    case 'set-current-view':
      return {
        ...state,
        view: action.payload,
      };
    default:
      throw new Error();
  }
};

const PageStateUIContext = createContext<{
  state: PageStateUI;
  dispatch: Dispatch<PageStateUIActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const PageStateUIProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <PageStateUIContext.Provider value={{ state, dispatch }}>{children}</PageStateUIContext.Provider>;
};

export const usePageStateUI = (): PageStateUI & { dispatch: Dispatch<PageStateUIActions> } => {
  const { state, dispatch } = useContext(PageStateUIContext);

  return { ...state, dispatch };
};
