import { useEffect, useState } from 'react';

import { PersonDTO } from '@wefox-scheduling/shared/interfaces';
import { userDataService, wefoxConfigService } from '@wefox-scheduling/shared/services';

export const useGetUserInformation = (): PersonDTO | null => {
  const [userInformation, setUserInformation] = useState<PersonDTO | null>(wefoxConfigService.getPersonalDetails());

  useEffect(() => {
    if (!userInformation) {
      userDataService.getUserData().then((personDTO) => {
        setUserInformation(personDTO);
      });
    }
  }, [userInformation]);

  return userInformation;
};
