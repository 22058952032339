import React from 'react';

import { i18n } from '@wefox/i18n';

const StepList = ({ activeStep, hasBroker }: { activeStep: number; hasBroker?: boolean }): JSX.Element => {
  const getStepStatus = (index: number): 'done' | 'active' | 'disabled' => {
    if (index < activeStep) {
      return 'done';
    }

    if (index <= activeStep) {
      return 'active';
    }

    return 'disabled';
  };

  return (
    <asteroid-card>
      <asteroid-vertical-stepper
        name={
          hasBroker
            ? i18n.getTranslation('RA_SUCCESS_title_step_1_with_broker')
            : i18n.getTranslation('RA_SUCCESS_title_step_1_without_broker')
        }
        status={getStepStatus(1)}
        description={
          hasBroker
            ? i18n.getTranslation('RA_SUCCESS_description_step_1_with_broker')
            : i18n.getTranslation('RA_SUCCESS_description_step_1_without_broker')
        }
        t-selector={`step-list-item${activeStep === 1 ? ' step-list-current' : ''}`}
        data-testid="step1"
      ></asteroid-vertical-stepper>
      <asteroid-vertical-stepper
        name={
          hasBroker
            ? i18n.getTranslation('RA_SUCCESS_title_step_2_with_broker')
            : i18n.getTranslation('RA_SUCCESS_title_step_2_without_broker')
        }
        status={getStepStatus(2)}
        description={
          hasBroker
            ? i18n.getTranslation('RA_SUCCESS_description_step_2_with_broker')
            : i18n.getTranslation('RA_SUCCESS_description_step_2_without_broker')
        }
        t-selector={`step-list-item${activeStep === 2 ? ' step-list-current' : ''}`}
        hide-step-line={true}
        data-testid="step2"
      ></asteroid-vertical-stepper>
    </asteroid-card>
  );
};

export { StepList };
