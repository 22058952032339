import React, { Dispatch, createContext, useContext, useReducer } from 'react';

import { BrokerDTO, RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';

import { TimeSlotModel } from './time-slots-section/model/time-slot.model';

interface PageState {
  availableTimeSlots: TimeSlotModel[];
  selectedDate: Date | null;
  selectedTimeSlot: TimeSlotModel | null;
  broker: BrokerDTO | null;
  riskAnalysis: RiskAnalysisDTO | null;
}

interface SetSelectedDateAction {
  type: 'set-selected-date';
  payload: Date;
}

interface SetAvailableTimeSlotsAction {
  type: 'set-available-time-slots';
  payload: TimeSlotModel[];
}

interface SetSelectedTimeSlotAction {
  type: 'set-selected-time-slot';
  payload: TimeSlotModel;
}

interface SetBrokerAction {
  type: 'set-broker';
  payload: BrokerDTO;
}

interface SetRiskAnalysisAction {
  type: 'set-risk-analysis';
  payload: RiskAnalysisDTO | null;
}

type PageStateActions =
  | SetSelectedDateAction
  | SetAvailableTimeSlotsAction
  | SetSelectedTimeSlotAction
  | SetBrokerAction
  | SetRiskAnalysisAction;

const initialState: PageState = {
  selectedDate: null,
  availableTimeSlots: [],
  selectedTimeSlot: null,
  broker: null,
  riskAnalysis: null,
};

const reducer = (state: PageState, action: PageStateActions): PageState => {
  switch (action.type) {
    case 'set-selected-date':
      return {
        ...state,
        selectedDate: action.payload,
        selectedTimeSlot: null,
      };
    case 'set-available-time-slots':
      return {
        ...state,
        availableTimeSlots: action.payload,
      };
    case 'set-selected-time-slot':
      return {
        ...state,
        selectedTimeSlot: action.payload,
      };
    case 'set-broker':
      return {
        ...state,
        broker: action.payload,
      };
    case 'set-risk-analysis':
      return {
        ...state,
        riskAnalysis: action.payload,
      };
    default:
      throw new Error();
  }
};

const PageStateContext = createContext<{
  state: PageState;
  dispatch: Dispatch<PageStateActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const PageStateProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <PageStateContext.Provider value={{ state, dispatch }}>{children}</PageStateContext.Provider>;
};

export const usePageState = (): PageState & { dispatch: Dispatch<PageStateActions> } => {
  const { state, dispatch } = useContext(PageStateContext);

  return { ...state, dispatch };
};
