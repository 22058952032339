import { useCallback, useEffect } from 'react';

import { EVENTS } from '@wefox-scheduling/shared/utils';

type EVENT_CALLBACKS = {
  [key in keyof typeof EVENTS]?: () => void;
};
export const useSetToolbar = (config: Record<string, unknown>, callbacks?: EVENT_CALLBACKS): void => {
  const mapCallbacks = (cb: EVENT_CALLBACKS): Record<string, () => void> => {
    return Object.entries({
      ...cb,
    }).reduce((acc, [key, value]) => {
      const event = EVENTS[key as keyof typeof EVENTS] as string;

      acc[event] = value;

      return acc;
    }, {} as Record<string, () => void>);
  };
  const setToolbar = useCallback((): void => {
    const event = new CustomEvent('setAsteroidToolbarConfig', {
      detail: config,
    });

    document.dispatchEvent(event);
  }, [config]);

  const setCallbacks = useCallback((): void => {
    const mappedCallbacks = mapCallbacks(callbacks || {});

    Object.keys(mappedCallbacks).forEach((key) => {
      document.addEventListener(key, mappedCallbacks[key]);
    });
  }, [callbacks]);

  const removeCallbacks = useCallback((): void => {
    const mappedCallbacks = mapCallbacks(callbacks || {});

    Object.keys(mappedCallbacks).forEach((key) => {
      document.removeEventListener(key, mappedCallbacks[key]);
    });
  }, [callbacks]);

  useEffect(() => {
    setToolbar();
    document.addEventListener('toolbarReady', setToolbar);
    setCallbacks();

    return () => {
      document.removeEventListener('toolbarReady', setToolbar);
      removeCallbacks();
    };
  }, [setToolbar, setCallbacks, removeCallbacks]);
};
