import { Field } from 'formik';
import React from 'react';

import { FormInput } from '@wefox-scheduling/shared/components';

import { FormFieldProperties } from '../interfaces/form-field.interface';

export const CommentField = ({ errors, id }: FormFieldProperties): JSX.Element => {
  return <Field name={id} component={FormInput} errors={errors[id]} format="multi" />;
};
