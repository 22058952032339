import { useCallback, useEffect, useState } from 'react';

import { analytics } from '@wefox/analytics';

import { usePageState } from '../../calendar-page.state';
import { brokerAvailabilityService } from '../services/broker-availability.service';

import { useNavigateToContactPage } from './use-get-navigate-to-different-flow.hook';

export const useGetBrokerAvailability = (): number[] => {
  const { broker } = usePageState();
  const navigateToContactPage = useNavigateToContactPage();
  const [disabledWeekdays, setDisabledWeekDays] = useState<number[]>([]);

  const setDisabledWeekDaysOrRedirect = useCallback(
    (days: number[] | null) => {
      if (days === null) {
        analytics.trackEvent('CONSULTATION_SCHEDULE_CONTACT_PREFERENCES', { origin: 'noScheduleAvailability' });
        navigateToContactPage();
      } else {
        setDisabledWeekDays(days);
      }
    },
    [navigateToContactPage]
  );

  useEffect(() => {
    if (broker) {
      brokerAvailabilityService.getData(broker.id).then(setDisabledWeekDaysOrRedirect);
    }
  }, [broker, setDisabledWeekDaysOrRedirect]);

  return disabledWeekdays;
};
