import React from 'react';
import { Navigate } from 'react-router';

type Props = {
  children: React.ReactNode;
  featureFlags: boolean[];
};
export const FeatureFlagGuard = ({ children, featureFlags }: Props): JSX.Element => {
  const atLeastOneFlagEnabled = featureFlags.some(Boolean);

  if (!atLeastOneFlagEnabled) {
    return <Navigate to={`/`} replace={true} />;
  }

  return <>{children}</>;
};
