import { useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateToBackUrl = (): (() => void) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const backUrl = searchParams.get('backUrl') || '';

  return () => {
    navigate(backUrl);
  };
};
