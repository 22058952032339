import React from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  isSubmitting: boolean;
  onBackButtonClick: () => void;
}

export const FormActionDesktop = ({ isSubmitting, onBackButtonClick }: Props): JSX.Element => {
  return (
    <div className="row">
      <div className="grid cols-2 g-huge">
        <div className="u-d-flex u-justify-content-start">
          <asteroid-button
            data-testid="back-button"
            type="button"
            leading-icon="arrow-left"
            kind="default"
            onClick={onBackButtonClick}
          ></asteroid-button>
        </div>
        <div className="u-d-flex u-justify-content-end">
          <asteroid-button disabled={isSubmitting} kind="primary" type="submit">
            <span>
              {isSubmitting && <asteroid-loader display="inline" size="small"></asteroid-loader>}
              {i18n.getTranslation('RA_GET_ADVICE_field_button_submit')}
            </span>
          </asteroid-button>
        </div>
      </div>
    </div>
  );
};
