import React, { Dispatch, createContext, useContext, useReducer } from 'react';

interface ContactPageStateUI {
  view: 'contact' | 'success' | 'error' | 'loading';
}

interface SetCurrentViewAction {
  type: 'set-current-view';
  payload: ContactPageStateUI['view'];
}

type ContactPageStateUIActions = SetCurrentViewAction;

const initialState: ContactPageStateUI = {
  view: 'contact',
};

const reducer = (state: ContactPageStateUI, action: ContactPageStateUIActions): ContactPageStateUI => {
  switch (action.type) {
    case 'set-current-view':
      return {
        ...state,
        view: action.payload,
      };
    default:
      throw new Error();
  }
};

const ContactPageStateUIContext = createContext<{
  state: ContactPageStateUI;
  dispatch: Dispatch<ContactPageStateUIActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ContactPageStateUIProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContactPageStateUIContext.Provider value={{ state, dispatch }}>{children}</ContactPageStateUIContext.Provider>
  );
};

export const useContactPageStateUI = (): ContactPageStateUI & { dispatch: Dispatch<ContactPageStateUIActions> } => {
  const { state, dispatch } = useContext(ContactPageStateUIContext);

  return { ...state, dispatch };
};
