import { ProductDTO, RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';
import { productsService } from '@wefox-scheduling/shared/services';

import { ContactFormValues } from '../interfaces/contact-values.interface';

export class ContactSubmiValuesWithoutBrokerModel {
  public contact_preferences: string[];
  public flow_type = 'CUSTOMER-RISK-ANALYSIS';
  public person: {
    phone: {
      number: string;
      prefix: string;
    };
  };
  public person_comment: string;
  public product_interests: ProductDTO[];
  public risk_analysis_id: string;

  constructor(formValues: ContactFormValues, riskAnalysis: RiskAnalysisDTO) {
    this.contact_preferences = formValues.contactPreferences;
    this.person = {
      phone: {
        prefix: formValues.phonePrefix,
        number: formValues.phone,
      },
    };
    this.person_comment = formValues.person_comment;
    this.product_interests = productsService.getTranslatedProducts(riskAnalysis.selected_products || []);
    this.risk_analysis_id = riskAnalysis.id;
  }
}
