import React from 'react';

import { useGetBreakpoint } from '@wefox-scheduling/shared/hooks';

import { usePageState } from '../calendar-page.state';

import { CalendarSection } from './calendar-section.component';
import { useGetBrokerAvailability } from './hooks/use-get-broker-availability.hook';

export const CalendarSectionController = (): JSX.Element => {
  const { dispatch } = usePageState();
  const disabledWeekDays = useGetBrokerAvailability();
  const { isMobile } = useGetBreakpoint();

  const handleOnChange = (selectedDate: Date): void => {
    dispatch({ type: 'set-selected-date', payload: selectedDate });
  };

  return <CalendarSection onChange={handleOnChange} disabledWeekDays={disabledWeekDays} showBorder={isMobile} />;
};
