import { authModule } from '@wefox/auth';
import { country } from '@wefox/i18n';

export const SUPPORTED_COUNTRY_CODES_SAFE = ['DE', 'CH'];

const DEFAULT_COUNTRY_CODE = 'DE';

const isAvailable = (countryCode: string): boolean => {
  return SUPPORTED_COUNTRY_CODES_SAFE.some((allowedCountryCode) => allowedCountryCode === countryCode);
};

export const getCountryCode = (): string => {
  if (isAvailable(authModule.getCountry())) {
    return authModule.getCountry();
  }

  return country.getCountryCode() || DEFAULT_COUNTRY_CODE;
};
