import { i18n } from '@wefox/i18n';

class SuccessPageService {
  public createTranslationForPreference(
    contactPreferencesUrl: string[],
    phoneNumber: string
  ): { placeHolders: string[] } {
    let str = '';

    if (contactPreferencesUrl.length > 2) {
      contactPreferencesUrl.splice(1, 0, ',');
      contactPreferencesUrl.splice(3, 0, i18n.getTranslation('GLOBAL_or'));
      str = contactPreferencesUrl.join(' ');
    }

    if (contactPreferencesUrl.length <= 2) {
      str = contactPreferencesUrl.join(` ${i18n.getTranslation('GLOBAL_or')} `);
    }

    return {
      placeHolders: [phoneNumber, str],
    };
  }
}

export const successPageService = new SuccessPageService();
