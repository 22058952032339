import React from 'react';

import { i18n } from '@wefox/i18n';

import { BottomBar, PageHeader, Portal } from '@wefox-scheduling/shared/components';
import { useGetBreakpoint, useSetToolbar } from '@wefox-scheduling/shared/hooks';
import { hiddenToolbar } from '@wefox-scheduling/shared/utils';

import { StepList } from './components/step-list.component';

interface ContactSuccessPageProps {
  hasBroker: boolean;
  descTranslationOptions: { placeHolders: string[] };
  handlerCTAClick: () => void;
}
export const ContactSuccessScreen = ({
  hasBroker,
  descTranslationOptions,
  handlerCTAClick,
}: ContactSuccessPageProps): JSX.Element => {
  useSetToolbar(hiddenToolbar());

  const { isMobile, isDesktop } = useGetBreakpoint();
  const onButtonClick = (): void => {
    handlerCTAClick();
  };

  return (
    <>
      <Portal>
        <div className="o-container o-container--full-height">
          <div className="o-grid">
            <div className="o-col-mobile-4 o-col-tablet-6 o-col-laptop-6 o-col-start-laptop-4">
              <div className={`u-mt-10 ' + ${isMobile ? 'u-text-center' : ''}`}>
                <asteroid-icon
                  icon-set="wecons-circle"
                  size="largest"
                  primary-color="success-50"
                  secondary-color="success-20"
                  icon="check"
                />
                <div className="u-mt-9">
                  <PageHeader title={'RA_SUCCESS_title'}></PageHeader>
                </div>
                <p className="u-mt-2">
                  {hasBroker
                    ? i18n.getTranslatedText('RA_SUCCESS_description_with_broker', descTranslationOptions)
                    : i18n.getTranslatedText('RA_SUCCESS_description_without_broker', descTranslationOptions)}
                </p>
                {isDesktop && (
                  <asteroid-button style={{ width: 'fit-content' }} kind="primary" onClick={onButtonClick}>
                    {i18n.getTranslation('CONSENT_3PP_modal_screen_success_cta')}
                  </asteroid-button>
                )}
              </div>
              <div className="u-mt-10">
                <StepList activeStep={1} hasBroker={hasBroker} />
              </div>
            </div>
          </div>
        </div>
      </Portal>
      {isMobile && (
        <BottomBar>
          <asteroid-button kind="primary" onClick={onButtonClick}>
            {i18n.getTranslation('CONSENT_3PP_modal_screen_success_cta')}
          </asteroid-button>
        </BottomBar>
      )}
    </>
  );
};
