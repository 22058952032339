import { httpRequestService } from '@wefox-scheduling/shared/services';

import { BrokerAppointmentBodyRequest } from '../interfaces/broker-appointment-body-request';

class CalendarEventDataService {
  public postBrokerAppointment(brokerId: string, params: BrokerAppointmentBodyRequest): Promise<void> {
    return httpRequestService.post(`brokers/${brokerId}/calendar/events`, {
      body: JSON.stringify({ event: params }),
    });
  }
}

export const calendarEventDataService = new CalendarEventDataService();
