import { FormikConfig, Form as FormikForm, FormikProvider, FormikValues, useFormik } from 'formik';
import React, { FC, FormEvent } from 'react';

interface Props<P> {
  formConfig: FormikConfig<FormikValues>;
  content: FC<{ formProps: ReturnType<typeof useFormik>; props: P }>;
  contentProps: P;
  formId: string;
}

export const Form = <P,>({ formConfig, content: Content, contentProps, formId }: Props<P>): JSX.Element => {
  const formikProps = useFormik(formConfig);
  const { handleSubmit } = formikProps;

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(e);
  };

  return (
    <FormikProvider value={formikProps}>
      <FormikForm noValidate onSubmit={handleFormSubmit} id={formId}>
        {/* Content is used without specifying its type, TypeScript should infer it */}
        <Content formProps={formikProps} props={contentProps} />
      </FormikForm>
    </FormikProvider>
  );
};
