import React, { Dispatch, createContext, useContext, useReducer } from 'react';

import { BrokerDTO, RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';

import { ContactFormValues } from './contact-form/interfaces/contact-values.interface';

interface ContactPageState {
  broker: BrokerDTO | null;
  selectedTimeSlot: string[];
  contactFormValues: ContactFormValues | null;
  riskAnalysis: RiskAnalysisDTO | null;
}

interface SetBrokerAction {
  type: 'set-broker';
  payload: BrokerDTO | null;
}

interface SetRiskAnalysisAction {
  type: 'set-risk-analysis';
  payload: RiskAnalysisDTO | null;
}

interface SetSelectedTimeSlots {
  type: 'set-selected-time-slot';
  payload: string[];
}

interface SetContactFormValues {
  type: 'set-contact-form-values';
  payload: ContactFormValues | null;
}

type ContactPageStateActions = SetBrokerAction | SetSelectedTimeSlots | SetContactFormValues | SetRiskAnalysisAction;

const initialState: ContactPageState = {
  broker: null,
  selectedTimeSlot: [],
  contactFormValues: null,
  riskAnalysis: null,
};

const reducer = (state: ContactPageState, action: ContactPageStateActions): ContactPageState => {
  switch (action.type) {
    case 'set-broker':
      return {
        ...state,
        broker: action.payload,
      };
    case 'set-risk-analysis':
      return {
        ...state,
        riskAnalysis: action.payload,
      };
    case 'set-selected-time-slot':
      return {
        ...state,
        selectedTimeSlot: action.payload,
      };
    case 'set-contact-form-values':
      return {
        ...state,
        contactFormValues: action.payload,
      };
    default:
      throw new Error();
  }
};

const ContactPageStateContext = createContext<{
  state: ContactPageState;
  dispatch: Dispatch<ContactPageStateActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ContactPageStateProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ContactPageStateContext.Provider value={{ state, dispatch }}>{children}</ContactPageStateContext.Provider>;
};

export const useContactPageState = (): ContactPageState & { dispatch: Dispatch<ContactPageStateActions> } => {
  const { state, dispatch } = useContext(ContactPageStateContext);

  return { ...state, dispatch };
};
