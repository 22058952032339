import React from 'react';
import { Navigate } from 'react-router';

import { useIsLoggedIn } from '@wefox-scheduling/shared/hooks';

interface Props {
  children: React.ReactNode;
}

export const PrivateRouteGuard = ({ children }: Props): JSX.Element => {
  if (!useIsLoggedIn()) {
    return <Navigate to={`/`} replace={true} />;
  }

  return <>{children}</>;
};
