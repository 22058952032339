import { httpRequestService } from '@wefox-scheduling/shared/services';

import { CalendarWeekAvailabilityDTO } from '../interfaces/calendar-week-availability';

export class BrokerAvailabilityDataService {
  public getBrokerAvailability = (brokerId: string): Promise<CalendarWeekAvailabilityDTO> => {
    return httpRequestService.get<CalendarWeekAvailabilityDTO>(`brokers/${brokerId}/calendar/configuration`);
  };
}

export const brokerAvailabilityDataService = new BrokerAvailabilityDataService();
