import React from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  message: string;
  type: 'warning' | 'error' | 'info';
}
export const TimeSlotsFeedback = ({ message, type }: Props): JSX.Element => {
  return (
    <asteroid-alert
      description={i18n.getTranslation(message)}
      type={type}
      data-testid="time-slots-feedback"
    ></asteroid-alert>
  );
};
