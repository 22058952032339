import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';

import { useNavigateToBackUrl } from '../use-navigate-to-back-url/use-get-navigate-to-back-url.hook';

export const useNavigateAfterSuccess = (riskAnalysis: RiskAnalysisDTO | null): (() => void) => {
  const navigate = useNavigate();
  const handleBackClick = useNavigateToBackUrl();

  const navigateAfterSuccess = useCallback(() => {
    if (riskAnalysis?.selected_products) {
      navigate('/advice');
    } else {
      handleBackClick();
    }
  }, [handleBackClick, navigate, riskAnalysis?.selected_products]);

  return navigateAfterSuccess;
};
