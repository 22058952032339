import React, { useEffect, useState } from 'react';

import { analytics } from '@wefox/analytics';
import { i18n } from '@wefox/i18n';

import { PageFeedback, PageHeader } from '@wefox-scheduling/shared/components';
import { useGetBreakpoint, useGetUserInformation, useNavigateAfterSuccess } from '@wefox-scheduling/shared/hooks';

import { usePageState } from '../calendar-page.state';
import { usePageStateUI } from '../calendar-page.state-ui';

import { ReviewScreenDesktop } from './review-screen-desktop.component';
import { ReviewScreenMobile } from './review-screen-mobile.component';
import { consultationReviewService } from './services/consultation-review.service';

export const ReviewScreenController = (): JSX.Element | null => {
  const { isMobile, isDesktop } = useGetBreakpoint();
  const { broker, riskAnalysis, selectedTimeSlot, selectedDate } = usePageState();
  const { dispatch } = usePageStateUI();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const user = useGetUserInformation();
  const navigateAfterSuccess = useNavigateAfterSuccess(riskAnalysis);

  useEffect(() => {
    analytics.trackEvent('CONSULTATION_SCHEDULE_SUMMARY_SHOW');
  }, []);

  if (!broker || !selectedTimeSlot || !selectedDate) {
    return null;
  }

  const handlePageSuccessCtaClick = (): void => {
    setSuccess(false);
    navigateAfterSuccess();
  };

  const handlePageErrorCtaClick = (): void => {
    setError(false);
    dispatch({ type: 'set-current-view', payload: 'review' });
  };

  const handleBackClick = (): void => {
    dispatch({ type: 'set-current-view', payload: 'consultation' });
  };

  const handleOnSubmit = async (comment: string): Promise<void> => {
    analytics.trackEvent('CONSULTATION_SCHEDULE_SUMMARY_CLICK');

    try {
      setLoading(true);
      await consultationReviewService.requestBrokerAppointment({
        comment,
        selectedDate,
        selectedTimeSlot,
        broker,
        user,
        selectedProducts: riskAnalysis?.selected_products || [],
      });
      setSuccess(true);
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <PageFeedback
        type="error"
        title={i18n.getTranslation('RA_contact_preferences_error_title')}
        description={i18n.getTranslation('RA_contact_preferences_error_description')}
        primaryCtaLabel={i18n.getTranslation('GLOBAL_back')}
        onPrimaryCtaClick={handlePageErrorCtaClick}
      />
    );
  }

  if (success) {
    return (
      <PageFeedback
        type="success"
        title={i18n.getTranslation('REVIEW_CONSULTATION_success_title')}
        description={i18n.getTranslation('REVIEW_CONSULTATION_success_description')}
        primaryCtaLabel={i18n.getTranslation('GLOBAL_continue')}
        onPrimaryCtaClick={handlePageSuccessCtaClick}
      />
    );
  }

  return (
    <>
      <PageHeader title="REVIEW_CONSULTATION_title" subTitle="REVIEW_CONSULTATION_description" />
      {isMobile && (
        <ReviewScreenMobile
          broker={broker}
          user={user}
          time={selectedTimeSlot}
          date={selectedDate}
          onBackClick={handleBackClick}
          onSubmit={handleOnSubmit}
          loading={loading}
        />
      )}
      {isDesktop && (
        <ReviewScreenDesktop
          broker={broker}
          user={user}
          time={selectedTimeSlot}
          date={selectedDate}
          onBackClick={handleBackClick}
          onSubmit={handleOnSubmit}
          loading={loading}
        />
      )}
    </>
  );
};
