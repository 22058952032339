import { RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';
import { httpRequestService } from '@wefox-scheduling/shared/services';

export class RiskAnalysisDataService {
  public getRiskAnalysis = (): Promise<RiskAnalysisDTO[]> => {
    return httpRequestService.get(`persons/me/risks-analysis`);
  };
}

export const riskAnalysisDataService = new RiskAnalysisDataService();
