import { FormikConfig, FormikValues } from 'formik/dist/types';
import React from 'react';

import { Form } from '@wefox-scheduling/shared/components';

import { ContactFormTemplate } from './components/contact-form-template.component';

interface Props {
  onBackButtonClick: () => void;
  formConfig: FormikConfig<FormikValues>;
  formId: string;
}

export const ContactForm = ({ formConfig, onBackButtonClick, formId }: Props): JSX.Element => {
  return (
    <Form
      formConfig={formConfig}
      content={ContactFormTemplate}
      contentProps={{
        onBackButtonClick,
        formId,
      }}
      formId={formId}
    />
  );
};
