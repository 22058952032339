import React from 'react';

import { Portal } from '@wefox-scheduling/shared/components';

interface Props {
  children: React.ReactNode;
  bottom?: string;
}

export const BottomBar = ({ children }: Props): JSX.Element | null => {
  return (
    <Portal hostSelector="#layout-bottom-sticky-container">
      <asteroid-bottom-bar>{children}</asteroid-bottom-bar>
    </Portal>
  );
};
