import React, { useEffect } from 'react';

import { analytics } from '@wefox/analytics';

import { PageHeader } from '@wefox-scheduling/shared/components';
import {
  useGetBreakpoint,
  useGetBrokerAndRiskAnalysis,
  useGetSelectedProducts,
  useNavigateToBackUrl,
} from '@wefox-scheduling/shared/hooks';

import { usePageState } from '../calendar-page.state';
import { usePageStateUI } from '../calendar-page.state-ui';
import { useNavigateToContactPage } from '../calendar-section/hooks/use-get-navigate-to-different-flow.hook';

import { ConsultationScreenSkeletonComponent } from './components/consultation-screen-skeleton.component';
import { ConsultationScreenDesktop } from './consultation-screen-desktop.component';
import { ConsultationScreenMobile } from './consultation-screen-mobile.component';

export const ConsultationScreenController = (): JSX.Element => {
  const { broker, riskAnalysis, error, loading } = useGetBrokerAndRiskAnalysis();
  const selectedProducts = useGetSelectedProducts(riskAnalysis?.product_recommendations);
  const { selectedDate, selectedTimeSlot, dispatch: dispatchToState } = usePageState();
  const { isMobile, isDesktop } = useGetBreakpoint();
  const { dispatch: dispatchToStateUI } = usePageStateUI();
  const handleBackClick = useNavigateToBackUrl();
  const navigateToContactPage = useNavigateToContactPage();

  if (broker === null || error) {
    analytics.trackEvent('CONSULTATION_SCHEDULE_CONTACT_PREFERENCES', { origin: 'noBroker' });
    navigateToContactPage();
  }

  const handleContinueClick = (): void => {
    analytics.trackEvent('CONSULTATION_SCHEDULE_CONTINUE_CLICK');
    dispatchToStateUI({ type: 'set-current-view', payload: 'review' });
  };

  useEffect(() => {
    analytics.trackEvent('CONSULTATION_SCHEDULE_SHOW');
  }, []);

  useEffect(() => {
    if (broker) {
      dispatchToState({ type: 'set-broker', payload: broker });
    }
    if (riskAnalysis) {
      dispatchToState({ type: 'set-risk-analysis', payload: { ...riskAnalysis, selected_products: selectedProducts } });
    }
  }, [broker, riskAnalysis, dispatchToState, selectedProducts]);

  if (loading) {
    return <ConsultationScreenSkeletonComponent />;
  }

  return (
    <>
      <PageHeader title="CONSULTATION_SCHEDULE_title" subTitle="CONSULTATION_SCHEDULE_description" />
      {isDesktop && (
        <ConsultationScreenDesktop
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
          isContinueButtonDisabled={!selectedDate || !selectedTimeSlot}
        />
      )}
      {isMobile && (
        <ConsultationScreenMobile
          onBackClick={handleBackClick}
          onContinueClick={handleContinueClick}
          isContinueButtonDisabled={!selectedDate || !selectedTimeSlot}
        />
      )}
    </>
  );
};
