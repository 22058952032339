import React, { useCallback, useEffect, useRef } from 'react';

interface Props {
  onChange: (selectedDate: Date) => void;
  disabledWeekDays: number[];
  showBorder: boolean;
}

interface HTMLAsteroidCalendar extends HTMLElement {
  disabledWeekdays: number[];
}

export const CalendarSection = ({ onChange, disabledWeekDays, showBorder }: Props): JSX.Element => {
  const calendarRef = useRef<HTMLElement>(null);
  const currentDate = new Date();
  const endDate = new Date(new Date().getTime() + 120 * 24 * 60 * 60 * 1000);

  const handleOnChange = useCallback(
    (evt: Event): void => {
      const { detail } = evt as CustomEvent;

      onChange(detail);
    },
    [onChange]
  );

  useEffect(() => {
    const asteroidCalendar = calendarRef.current as HTMLAsteroidCalendar;

    if (asteroidCalendar) {
      asteroidCalendar.addEventListener('asteroidCalendarDateSelected', handleOnChange);
      asteroidCalendar.disabledWeekdays = disabledWeekDays;
    }

    return () => {
      asteroidCalendar?.removeEventListener('asteroidCalendarDateSelected', handleOnChange);
    };
  }, [disabledWeekDays, handleOnChange]);

  return (
    <asteroid-calendar
      ref={calendarRef}
      start-date={currentDate}
      end-date={endDate}
      data-testId="asteroid-calendar"
      disable-weekends
      border={showBorder}
    />
  );
};
