import { ContactFormValues } from '../interfaces/contact-values.interface';

export class ContactSubmiValuesWithBrokerModel {
  public comment: string;
  public contact_preferences: string[];
  public person_id = '';
  public phone: {
    number: string;
    prefix: string;
  };

  constructor(formValues: ContactFormValues, personId: string) {
    this.comment = formValues.person_comment;
    this.contact_preferences = formValues.contactPreferences;
    this.phone = {
      prefix: formValues.phonePrefix,
      number: formValues.phone,
    };
    this.person_id = personId;
  }
}
