import { TimeSlotDTO } from '../interfaces/time-slots.interface';
import { TimeSlotModel } from '../model/time-slot.model';

export class TimeSlotsService {
  public createTimeSlotsModel(timeSlotsDTO?: TimeSlotDTO[]): TimeSlotModel[] | null {
    return timeSlotsDTO ? timeSlotsDTO.map((slot) => new TimeSlotModel(slot)) : null;
  }

  public parseUTCDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  }
}

export const timeSlotsService = new TimeSlotsService();
