import React from 'react';

import { useNavigateToBackUrl } from '@wefox-scheduling/shared/hooks';

import { useContactPageState } from '../contact-page.state';
import { useContactPageStateUI } from '../contact-page.state-ui';

import { ContactForm } from './contact-form.component';
import { useContactFormConfig } from './hooks/use-contact-form-contact-config.hook';
import { useGetPhoneNumber } from './hooks/use-get-phone-number.hook';
import { ContactFormValues } from './interfaces/contact-values.interface';
import { contactFormService } from './services/contact-form.service';

export const ContactFormController = (): JSX.Element => {
  const navigateToBackUrl = useNavigateToBackUrl();
  const { broker, riskAnalysis } = useContactPageState();

  const { dispatch: dispatchToContactPageState } = useContactPageState();
  const { dispatch: dispatchToContactPageStateUI } = useContactPageStateUI();

  const submitNavigation = async (values: ContactFormValues): Promise<void> => {
    try {
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'loading' });
      if (broker) {
        await contactFormService.handleFormSubmitWithBroker(values, broker.id);
      } else if (riskAnalysis) {
        await contactFormService.handleFormSubmitWithoutBroker(values, riskAnalysis);
      }
      dispatchToContactPageState({ type: 'set-selected-time-slot', payload: values.contactPreferences });
      dispatchToContactPageState({ type: 'set-selected-time-slot', payload: values.contactPreferences });
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'success' });
    } catch (err) {
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'error' });
    }
  };

  const handleFormSubmit = async (values: ContactFormValues): Promise<void> => {
    dispatchToContactPageState({ type: 'set-contact-form-values', payload: values });
    submitNavigation(values);
  };

  const phoneNumber = useGetPhoneNumber();
  const formConfig = useContactFormConfig({
    initialValues: contactFormService.getInitialValues(phoneNumber),
    onSubmit: handleFormSubmit,
  });

  return <ContactForm formConfig={formConfig} onBackButtonClick={navigateToBackUrl} formId="scheduling-contact" />;
};
