import React from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  brokerTitle: string;
}

export const BrokerInformation = ({ brokerTitle }: Props): JSX.Element => {
  return (
    <asteroid-card class="u-mb-10 u-d-flex">
      <asteroid-icon size="large" class="u-mr-2" icon-set="three-dimensional" icon="broker"></asteroid-icon>
      <div>
        <div data-testid="broker-name" className="u-ty-heading-medium">
          {brokerTitle}
        </div>
        <p className="u-mt-3 u-color-neutral-70">{i18n.getTranslation('RA_GET_ADVICE_BROKER_SUBTITLE')}</p>
      </div>
    </asteroid-card>
  );
};
