import React from 'react';

import { i18n } from '@wefox/i18n';

import { TimeSlotsAvailability } from './components/time-slots-availability.component';
import { TimeSlotsFeedback } from './components/time-slots-feedback.component';
import { TimeSlotsSkeleton } from './components/time-slots-skeleton.component';
import { TimeSlotModel } from './model/time-slot.model';

interface Props {
  onTimeSlotSelection: (selectedTimeSlot: TimeSlotModel) => void;
  loading: boolean;
  timeSlots: TimeSlotModel[] | null;
  error: boolean;
  selectedDate: Date | null;
}
export const TimeSlotSection = ({
  onTimeSlotSelection,
  error,
  timeSlots,
  loading,
  selectedDate,
}: Props): JSX.Element => {
  const showNoSelectedDay = !loading && selectedDate === null && !error;
  const showTimeSlots = !loading && timeSlots && !!timeSlots.length && !error;
  const showNoAvailableTimeSlots = !loading && !error && (!timeSlots || !timeSlots?.length) && selectedDate !== null;
  const showErrorMessage = !loading && error && !timeSlots;

  return (
    <div className="u-d-flex u-flex-column">
      <div className="u-ty-body-sb u-mb-4 u-color-primary-60">
        {i18n.getTranslation('CONSULTATION_SCHEDULE_available_slots')}
      </div>
      {loading && <TimeSlotsSkeleton />}
      {showNoSelectedDay && <TimeSlotsFeedback message={'CONSULTATION_SCHEDULE_no_day_selected'} type="info" />}
      {showTimeSlots && <TimeSlotsAvailability timeSlots={timeSlots} onChange={onTimeSlotSelection} />}
      {showNoAvailableTimeSlots && (
        <TimeSlotsFeedback message={'CONSULTATION_SCHEDULE_no_broker_availability'} type="warning" />
      )}
      {showErrorMessage && <TimeSlotsFeedback message={'CONSULTATION_SCHEDULE_no_broker_availability'} type="error" />}
    </div>
  );
};
