import { httpRequestService } from '@wefox-scheduling/shared/services';

import { ContactSubmiValuesWithBrokerModel } from '../contact-form/models/contact-submit-with-broker.interface';
import { ContactSubmiValuesWithoutBrokerModel } from '../contact-form/models/contact-submit-without-broker.interface';

export class ContactFormDataService {
  public createCustomerLead = (data: ContactSubmiValuesWithoutBrokerModel): Promise<void> => {
    return httpRequestService.post(`persons/me/leads`, {
      body: JSON.stringify(data),
    });
  };

  public sendNotificationForBroker = (brokerId: string, data: ContactSubmiValuesWithBrokerModel): Promise<void> => {
    return httpRequestService.post(`brokers/${brokerId}/notifications/advices`, {
      body: JSON.stringify(data),
    });
  };
}

export const contactFormDataService = new ContactFormDataService();
