import { PhoneDTO, RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';
import { wefoxConfigService } from '@wefox-scheduling/shared/services';

import { contactFormDataService } from '../../services/contact-form-data.service';
import { ContactFormValues } from '../interfaces/contact-values.interface';
import { ContactSubmiValuesWithBrokerModel } from '../models/contact-submit-with-broker.interface';
import { ContactSubmiValuesWithoutBrokerModel } from '../models/contact-submit-without-broker.interface';

export class ContactFormService {
  public getInitialValues(phoneNumber: PhoneDTO | null): ContactFormValues {
    return {
      phonePrefix: phoneNumber?.prefix || '',
      phone: phoneNumber?.number || '',
      contactPreferences: [],
      person_comment: '',
    };
  }

  public handleFormSubmitWithBroker(values: ContactFormValues, brokerId: string): Promise<void> {
    const dataToSend = new ContactSubmiValuesWithBrokerModel(values, wefoxConfigService.getProfileId());

    return contactFormDataService.sendNotificationForBroker(brokerId, dataToSend);
  }

  public handleFormSubmitWithoutBroker(values: ContactFormValues, riskAnalysis: RiskAnalysisDTO): Promise<void> {
    const dataToSend = new ContactSubmiValuesWithoutBrokerModel(values, riskAnalysis);

    return contactFormDataService.createCustomerLead(dataToSend);
  }
}

export const contactFormService = new ContactFormService();
