import { BrokerDTO } from '@wefox-scheduling/shared/interfaces';
import { httpRequestService, wefoxConfigService } from '@wefox-scheduling/shared/services';

export class BrokerDataService {
  public getBrokerInfo = (): Promise<BrokerDTO> => {
    return httpRequestService.get(`profiles/${wefoxConfigService.getProfileId()}/broker`);
  };
}

export const brokerDateService = new BrokerDataService();
