import { httpRequestService } from '@wefox-scheduling/shared/services';

import { AvailableTimeSlotsDTO } from '../interfaces/time-slots.interface';

export class TimeSlotsDataService {
  public getAvailableTimeSlotsByDay = (day: string, brokerId: string): Promise<AvailableTimeSlotsDTO> => {
    return httpRequestService.get<AvailableTimeSlotsDTO>(`brokers/${brokerId}/calendar/availability?date=${day}`);
  };
}

export const timeSlotsDataService = new TimeSlotsDataService();
