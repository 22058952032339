import { useEffect, useState } from 'react';

import { ViewBreakpointObserver } from '@wefox/view-breakpoint-observer';
import { Breakpoint } from '@wefox/view-breakpoint-observer/interfaces';

import { VIEWS } from '@wefox-scheduling/shared/interfaces';

interface BreakpointObserverViews {
  isMobile: boolean;
  isDesktop: boolean;
}
export const useGetBreakpoint = (): BreakpointObserverViews => {
  const [currentView, setCurrentView] = useState<string>(ViewBreakpointObserver.getCurrentBreakpoint().name);

  useEffect(() => {
    const handleBreakpointChanged = (evt: Event): void => {
      const customEvt = evt as CustomEvent<Breakpoint>;

      setCurrentView(customEvt.detail.name);
    };

    window.addEventListener('breakpointChanged', handleBreakpointChanged);

    return () => {
      window.removeEventListener('breakpointChanged', handleBreakpointChanged);
    };
  }, []);

  const getObserverFor = (...views: string[]): boolean => {
    return views.includes(currentView);
  };

  return {
    isMobile: getObserverFor(VIEWS.MOBILE, VIEWS.TABLET),
    isDesktop: getObserverFor(VIEWS.DESKTOP, VIEWS.LAPTOP, VIEWS.TV),
  };
};
