import { TimeSlotDTO } from '../interfaces/time-slots.interface';

export class TimeSlotModel {
  public displayedValue: string;
  public value: TimeSlotDTO;

  constructor(timeSlot: TimeSlotDTO) {
    this.value = timeSlot;
    this.displayedValue = this._formatTimeRange(timeSlot);
  }

  private _formatTimeRange = (timeSlot: TimeSlotDTO): string => {
    const startTime = timeSlot.from.split(' ');
    const endTime = timeSlot.to.split(' ');

    if (startTime[1] === endTime[1]) {
      return `${startTime[0]} - ${endTime[0]} ${endTime[1].toLowerCase()}`;
    }

    return `${timeSlot.from.toLowerCase()} - ${timeSlot.to.toLowerCase()}`;
  };
}
