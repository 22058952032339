import React from 'react';

import { PageStateProvider } from './calendar-page.state';
import { PageStateUIProvider } from './calendar-page.state-ui';
import { Layout } from './layout/layout.component';

export const CalendarPage = (): JSX.Element => {
  return (
    <PageStateProvider>
      <PageStateUIProvider>
        <Layout />
      </PageStateUIProvider>
    </PageStateProvider>
  );
};
