import { useFormik } from 'formik';
import React from 'react';

import { useGetBreakpoint } from '@wefox-scheduling/shared/hooks';

import { CommentField } from './comment-field.component';
import { FormActionDesktop } from './form-actions-desktop.component';
import { FormActionMobile } from './form-actions-mobile.component';
import { PhoneNumberField } from './phone-number-field.component';
import { SelectionBoxOptionsField } from './selection-box-options-field.component';

interface Props {
  formProps: ReturnType<typeof useFormik>;
  props: { onBackButtonClick: () => void; formId: string };
}

export const ContactFormTemplate = ({ formProps, props }: Props): JSX.Element => {
  const { onBackButtonClick } = props;
  const { errors, isSubmitting } = formProps;
  const { isMobile, isDesktop } = useGetBreakpoint();

  return (
    <>
      <div className="row">
        <PhoneNumberField id="phone" errors={errors} />
      </div>
      <div className="row">
        <SelectionBoxOptionsField id="contactPreferences" errors={errors} />
      </div>
      <div className="row">
        <CommentField id="person_comment" errors={errors} />
      </div>

      {isMobile && <FormActionMobile formId={props.formId} isSubmitting={isSubmitting}></FormActionMobile>}

      {isDesktop && (
        <FormActionDesktop isSubmitting={isSubmitting} onBackButtonClick={onBackButtonClick}></FormActionDesktop>
      )}
    </>
  );
};
