import React from 'react';

import { contactFormService } from '../contact-form/services/contact-form.service';
import { useContactPageState } from '../contact-page.state';
import { useContactPageStateUI } from '../contact-page.state-ui';

import { ContactErrorScreen } from './contact-error-screen.component';

export const ContactErrorScreenController = (): JSX.Element => {
  const { broker, contactFormValues, riskAnalysis } = useContactPageState();

  const { dispatch: dispatchToContactPageStateUI } = useContactPageStateUI();
  const handlerClick = async (): Promise<void> => {
    if (!contactFormValues) {
      return;
    }

    try {
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'loading' });
      if (broker) {
        await contactFormService.handleFormSubmitWithBroker(contactFormValues, broker.id);
      } else if (riskAnalysis) {
        await contactFormService.handleFormSubmitWithoutBroker(contactFormValues, riskAnalysis);
      }
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'success' });
    } catch (err) {
      dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'error' });
    }
  };

  return <ContactErrorScreen handlePageErrorCtaClick={handlerClick} />;
};
