import { useEffect, useState } from 'react';

import { useGetUserInformation } from '@wefox-scheduling/shared/hooks';
import { PhoneDTO } from '@wefox-scheduling/shared/interfaces';

import { phonePrefix } from '../consts/phone-prefixes.const';

export const useGetPhoneNumber = (): PhoneDTO | null => {
  const [phoneNumber, setPhoneNumber] = useState<PhoneDTO | null>(null);
  const userInformation = useGetUserInformation();

  // TODO move to service
  const getMobileNumber = (phones: PhoneDTO[]): PhoneDTO | void =>
    phones.find((phone) => phone.type.toLowerCase() === 'mobile');

  const formatPhoneNumber = (phone: PhoneDTO | void): PhoneDTO => {
    if (!phone) {
      return {
        prefix: '',
        number: '',
        id: '',
        type: '',
      };
    }

    const prefixObj = phonePrefix.find((el) => [0, 1].includes(phone.number.indexOf(el.prefix)));
    const prefix = prefixObj?.prefix ?? phone.prefix;

    return {
      prefix: prefix || '',
      number: prefix ? phone.number.replace(prefix, '').replace('+', '') : phone.number,
      id: phone.id,
      type: phone.type,
    };
  };

  useEffect(() => {
    if (userInformation) {
      const phone = getMobileNumber(userInformation.phones);
      const formattedPhoneNumber = formatPhoneNumber(phone);

      setPhoneNumber(formattedPhoneNumber);
    }
  }, [userInformation]);

  return phoneNumber;
};
