import React from 'react';

import { useNavigateToBackUrl, useSetToolbar } from '@wefox-scheduling/shared/hooks';
import { backNavigationConfig } from '@wefox-scheduling/shared/utils';

import { ContactPageStateProvider } from './contact-page.state';
import { ContactPageStateUIProvider } from './contact-page.state-ui';
import { Layout } from './layout/layout.component';

export const ContactPage = (): JSX.Element => {
  const backNavigationClick = useNavigateToBackUrl();

  useSetToolbar(backNavigationConfig('RA_CONTACT_PREFERENCES_TITLE'), { backNavigationClick });

  return (
    <ContactPageStateProvider>
      <ContactPageStateUIProvider>
        <Layout />
      </ContactPageStateUIProvider>
    </ContactPageStateProvider>
  );
};
