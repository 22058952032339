import { useEffect, useState } from 'react';

import { analytics } from '@wefox/analytics';

import { usePageState } from '../../calendar-page.state';
import { TimeSlotModel } from '../model/time-slot.model';
import { timeSlotsDataService } from '../services/time-slots-data.service';
import { timeSlotsService } from '../services/time-slots.service';

interface GetTimeSlotsReturn {
  timeSlots: TimeSlotModel[] | null;
  loading: boolean;
  error: boolean;
}

export const useGetAvailableTimeSlots = (): GetTimeSlotsReturn => {
  const [timeSlots, setTimeSlots] = useState<TimeSlotModel[] | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedDate, broker } = usePageState();

  useEffect(() => {
    if (selectedDate && broker) {
      setLoading(true);
      setError(false);

      timeSlotsDataService
        .getAvailableTimeSlotsByDay(timeSlotsService.parseUTCDate(selectedDate), broker.id)
        .then((slots) => {
          setTimeSlots(timeSlotsService.createTimeSlotsModel(slots.available_hour_slots));
          setLoading(false);
          analytics.trackEvent('CONSULTATION_SCHEDULE_DAY_AVAILABILITY', {
            num_available_slots: slots.available_hour_slots?.length || 0,
          });
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [selectedDate, broker]);

  return {
    timeSlots,
    loading,
    error,
  };
};
