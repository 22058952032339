import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ProductDTO } from '@wefox-scheduling/shared/interfaces';

export const useGetSelectedProducts = (productRecommendations: ProductDTO[] | undefined): ProductDTO[] | null => {
  const [searchParams] = useSearchParams();
  const selectedProductsIdsQueryParam = searchParams.get('productsIds');
  const [selectedProducts, setSelectedProducts] = useState<ProductDTO[] | null>(null);

  useEffect(() => {
    if (selectedProductsIdsQueryParam === null || !productRecommendations) {
      return;
    }

    const selectedProductsIds = selectedProductsIdsQueryParam.split(',');
    const filteredProducts = productRecommendations.filter((product) => selectedProductsIds.includes(product.id));

    setSelectedProducts(filteredProducts);
  }, [selectedProductsIdsQueryParam, productRecommendations]);

  return selectedProducts;
};
