import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BrokerDTO, RiskAnalysisDTO } from '@wefox-scheduling/shared/interfaces';
import { brokerDateService, riskAnalysisDataService } from '@wefox-scheduling/shared/services';

interface GetBrokerReturn {
  broker: BrokerDTO | null | undefined;
  riskAnalysis: RiskAnalysisDTO | null | undefined;
  loading: boolean;
  error: boolean;
}

export const useGetBrokerAndRiskAnalysis = (): GetBrokerReturn => {
  const [searchParams] = useSearchParams();
  const selectedProductsIdsQueryParam = searchParams.get('productsIds');

  const [broker, setBroker] = useState<BrokerDTO | null>();
  const [riskAnalysis, setRiskAnalysis] = useState<RiskAnalysisDTO | null>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const [riskAnalysisDTO, brokerDTO] = await Promise.all([
          riskAnalysisDataService.getRiskAnalysis(),
          brokerDateService.getBrokerInfo(),
        ]);

        setBroker(Object.keys(brokerDTO)?.length ? brokerDTO : null);
        if (riskAnalysisDTO?.length) {
          setRiskAnalysis(riskAnalysisDTO[0]);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProductsIdsQueryParam]);

  return {
    broker,
    riskAnalysis,
    error,
    loading,
  };
};
