import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  field: { value: string; name: string };
  errors: string;
  format: 'multi' | 'textarea';
}

export const FormInput = ({ field, errors, format }: Props): JSX.Element => {
  const reference = useRef<HTMLElement>(null);
  const { setFieldValue } = useFormikContext<{ [key: string]: string }>();

  const handleOnChange = (event: CustomEvent): void => {
    const newValue = event.detail;

    setFieldValue(field.name, newValue);
  };

  useEffect(() => {
    const asteroidInput = reference.current;

    asteroidInput?.addEventListener('asteroidInputOnChange', handleOnChange as EventListener);

    return () => {
      asteroidInput?.removeEventListener('asteroidInputOnChange', handleOnChange as EventListener);
    };
  });

  return (
    <div className={cn('form-group', 'u-my-6')}>
      <asteroid-form-group-input label={i18n.getTranslation(`RA_GET_ADVICE_field_${field.name}`)}>
        <asteroid-input
          data-testid="asteroid-input"
          name={field.name}
          placeholder={i18n.getTranslation(`RA_GET_ADVICE_placeholder_${field.name}`)}
          value={field.value}
          format={format}
          ref={reference}
          status={errors != null && 'danger'}
          error-message={i18n.getTranslation(errors)}
        ></asteroid-input>
      </asteroid-form-group-input>
    </div>
  );
};
