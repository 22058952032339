import React from 'react';

import { useGetBreakpoint } from '@wefox-scheduling/shared/hooks';

export const ConsultationScreenSkeletonComponent = (): JSX.Element => {
  const { isMobile } = useGetBreakpoint();

  if (isMobile) {
    return (
      <asteroid-skeleton-loader width="100%" height="678" aspect-ratio="false">
        <svg viewBox="0 0 327 678">
          <rect width="252.421" height="24" rx="4" fill="#EBEAE8" />
          <rect y="40" width="327" height="18" rx="4" fill="#EBEAE8" />
          <rect y="65" width="327" height="18" rx="4" fill="#EBEAE8" />
          <rect y="91" width="327" height="18" rx="4" fill="#EBEAE8" />
          <rect y="133" width="327" height="344" rx="16" fill="white" />
          <rect x="104.564" y="161" width="116.922" height="16" rx="4" fill="#EBEAE8" />
          <rect x="21.8633" y="221.08" width="281.372" height="40" rx="4" fill="#EBEAE8" />
          <rect x="21.8633" y="269.08" width="281.372" height="40" rx="4" fill="#EBEAE8" />
          <rect x="21.8633" y="317.08" width="281.372" height="40" rx="4" fill="#EBEAE8" />
          <rect x="21.8633" y="365.08" width="281.372" height="40" rx="4" fill="#EBEAE8" />
          <rect x="21.8633" y="413.08" width="81.75" height="40" rx="4" fill="#EBEAE8" />
          <rect x="280.421" y="157" width="22.814" height="24" rx="4" fill="#F7F6F4" />
          <rect x="21.8633" y="157" width="22.814" height="24" rx="4" fill="#F7F6F4" />
          <rect y="501" width="122" height="18" rx="4" fill="#EBEAE8" />
          <rect x="-0.00012207" y="543" width="155.816" height="56" rx="4" fill="white" />
          <rect x="-0.00012207" y="615" width="155.816" height="56" rx="4" fill="white" />
          <rect x="171.184" y="543" width="155.816" height="56" rx="4" fill="white" />
          <rect x="171.184" y="615" width="155.816" height="56" rx="4" fill="white" />
        </svg>
      </asteroid-skeleton-loader>
    );
  }

  return (
    <asteroid-skeleton-loader class="mt-laptop-huge2x" width="100%" height="564" aspect-ratio="false">
      <svg viewBox="0 0 820 564">
        <rect x="8.50073" width="350.438" height="24" rx="4" fill="#EBEAE8" />
        <rect x="8" y="40" width="800" height="18" rx="4" fill="#EBEAE8" />
        <rect x="8.50171" y="66" width="179.224" height="18" rx="4" fill="#EBEAE8" />
        <rect x="12" y="124" width="796" height="344" rx="8" fill="white" />
        <rect x="12" y="124" width="383" height="344" rx="8" fill="white" />
        <rect x="12" y="124" width="383" height="344" rx="16" fill="white" />
        <rect x="134.471" y="152" width="136.945" height="16" rx="4" fill="#EBEAE8" />
        <rect x="37.6074" y="212.08" width="329.558" height="40" rx="4" fill="#EBEAE8" />
        <rect x="37.6074" y="260.08" width="329.558" height="40" rx="4" fill="#EBEAE8" />
        <rect x="37.6074" y="308.08" width="329.558" height="40" rx="4" fill="#EBEAE8" />
        <rect x="37.6074" y="356.08" width="329.558" height="40" rx="4" fill="#EBEAE8" />
        <rect x="37.6074" y="404.08" width="95.75" height="40" rx="4" fill="#EBEAE8" />
        <rect x="340.445" y="148" width="26.7209" height="24" rx="4" fill="#F7F6F4" />
        <rect x="37.6074" y="148" width="26.7209" height="24" rx="4" fill="#F7F6F4" />
        <rect x="427" y="189" width="122" height="18" rx="4" fill="#EBEAE8" />
        <rect x="427" y="239" width="172" height="56" rx="4" fill="#EBEAE8" />
        <rect x="616" y="239" width="172" height="56" rx="4" fill="#EBEAE8" />
        <rect x="707" y="508" width="101" height="56" rx="28" fill="#F1F0EE" />
      </svg>
    </asteroid-skeleton-loader>
  );
};
