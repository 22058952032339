import { PersonDTO } from '@wefox-scheduling/shared/interfaces';
import { httpRequestService, wefoxConfigService } from '@wefox-scheduling/shared/services';

export class UserDataService {
  public getUserData = (): Promise<PersonDTO> => {
    return httpRequestService.get(`persons/me/profiles/${wefoxConfigService.getProfileId()}`);
  };
}

export const userDataService = new UserDataService();
