import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { i18n } from '@wefox/i18n';

interface Props {
  title: { key: string; fallback: string };
  subtitle: { key: string; fallback: string };
  state: string;
  field: { value: string[]; name: string };
  value: string;
  isLastElement: boolean;
  props: { type: 'radio' | 'checkbox' };
}

export const SelectionBox = ({ title, subtitle, state, field, value, props, isLastElement }: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext<{ [key: string]: string[] }>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const reference = useRef<HTMLElement>(null);
  const handleClick = useCallback(() => {
    const selectedOptions = field.value;

    if (selectedOptions.includes(value)) {
      selectedOptions.splice(selectedOptions.indexOf(value), 1);
    } else {
      selectedOptions.push(value);
    }
    if (field.name) {
      setFieldValue(field.name, selectedOptions);
    }
  }, [field.name, field.value, setFieldValue, value]);

  useEffect(() => {
    const asteroidSelection = reference.current;
    const cleanListeners = (): void => {
      asteroidSelection?.removeEventListener('wefoxRadioOnClick', handleClick);
    };

    if (field.name) {
      if (isChecked !== field.value.includes(value)) {
        setIsChecked(field.value.includes(value));
      }

      asteroidSelection?.addEventListener('wefoxRadioOnClick', handleClick);
    }

    return cleanListeners;
  }, [isChecked, field, value, handleClick]);

  return (
    <asteroid-selection-box
      data-testid="selection-box"
      checked={isChecked}
      name={field.name}
      value={value}
      type={props.type}
      state={state}
      ref={reference}
      class={cn(!isLastElement && 'u-mb-2')}
    >
      <span className="u-ty-body-sb">{i18n.getTranslation(title.key) || title.fallback}</span>
      <span className="u-d-block u-ty-body u-color-neutral-70">
        {i18n.getTranslation(subtitle.key) || subtitle.fallback}
      </span>
    </asteroid-selection-box>
  );
};
