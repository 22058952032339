import React, { useState } from 'react';

import { i18n } from '@wefox/i18n';

import { BrokerDTO, PersonDTO, ProductDTO } from '@wefox-scheduling/shared/interfaces';
import { productsService } from '@wefox-scheduling/shared/services';

import { TimeSlotModel } from '../time-slots-section/model/time-slot.model';

import { InformationContent } from './components/information-content.component';
import { formatDateAndTime } from './utils/date-and-time';

interface Props {
  broker: BrokerDTO | null;
  user: PersonDTO | null;
  loading: boolean;
  time: TimeSlotModel;
  date: Date;
  onBackClick: () => void;
  onSubmit: (comment: string) => void;
}

export const ReviewScreenDesktop = ({
  broker,
  user,
  time,
  date,
  onSubmit,
  onBackClick,
  loading,
}: Props): JSX.Element => {
  const [comment, setComment] = useState('');
  const dateAndTime = formatDateAndTime(date, time);
  const handleSubmit = (): void => {
    onSubmit(comment);
  };
  const handleInputChange = (val: string): void => {
    setComment(val);
  };

  return (
    <>
      <div className="u-mt-8">
        <InformationContent
          brokerFullName={`${broker?.first_name} ${broker?.last_name}`}
          email={user?.email}
          dateAndTime={dateAndTime}
          onInputChange={handleInputChange}
          comment={comment}
        />
        <div className="u-mb-10 u-d-flex u-justify-content-between u-pt-8">
          <asteroid-button data-testid="back-button" type="submit" onClick={onBackClick}>
            <asteroid-icon icon="arrow-left"></asteroid-icon>
          </asteroid-button>
          <asteroid-button disabled={loading} onClick={handleSubmit} kind="primary">
            <span>
              {loading && <asteroid-loader display="inline" size="small"></asteroid-loader>}
              {i18n.getTranslation('GLOBAL_submit')}
            </span>
          </asteroid-button>
        </div>
      </div>
    </>
  );
};
