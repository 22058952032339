import { PersonDTO } from '@wefox-scheduling/shared/interfaces';

class WefoxConfigService {
  public getGateWay(): string {
    return window.wefoxConfig?.api.url || '';
  }

  public getPersonalDetails(): PersonDTO {
    return window.wefoxConfig?.user?.personalDetails || null;
  }

  public getProfileId(): string {
    return window.wefoxConfig?.api?.profileId || '';
  }

  public getXProfileHeader(): string {
    return window.wefoxConfig?.api?.headers?.['X-Profile'] || '';
  }
}

export const wefoxConfigService = new WefoxConfigService();
