import React from 'react';

import { usePageStateUI } from '../calendar-page.state-ui';
import { ConsultationScreenController } from '../consultation-screen/consultation-screen.controller';
import { ReviewScreenController } from '../review-screen/review-screen.controller';

export const Layout = (): JSX.Element => {
  const { view } = usePageStateUI();

  return (
    <>
      {view === 'consultation' && (
        <div className="o-container">
          <div className="o-grid">
            <div className="o-col-mobile-4 o-col-tablet-6 o-col-laptop-8 o-col-start-laptop-3">
              <ConsultationScreenController />
            </div>
          </div>
        </div>
      )}
      {view === 'review' && (
        <div className="o-container">
          <div className="o-grid">
            <div className="o-col-mobile-4 o-col-tablet-6 o-col-laptop-6 o-col-start-laptop-4">
              <ReviewScreenController />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
