import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const useNavigateToContactPage = (): (() => void) => {
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  let queryParams = '';

  urlSearchParams.forEach((value, key) => {
    queryParams += `&${key}=${value}`;
  });
  const url = `/scheduling/contact${queryParams ? `?${queryParams}` : ''}`;

  return useCallback(() => navigate(url, { replace: true }), [url, navigate]);
};
