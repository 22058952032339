import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './root.component.scss';
import { RoutesComponent } from './routes.component';

export const Root = (): JSX.Element => {
  return (
    <StrictMode>
      <BrowserRouter>
        <RoutesComponent />
      </BrowserRouter>
    </StrictMode>
  );
};
