import React from 'react';

export const TimeSlotsSkeleton = (): JSX.Element => {
  return (
    <asteroid-skeleton-loader width="100%" height="56" aspect-ratio="false">
      <svg viewBox="0 0 361 56">
        <rect x="0.5" width="172" height="56" rx="4" fill="#F1F0EE" />
        <rect x="188.5" width="172" height="56" rx="4" fill="#F1F0EE" />
      </svg>
    </asteroid-skeleton-loader>
  );
};
