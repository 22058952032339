import React, { useEffect, useState } from 'react';

import { useNavigateAfterSuccess } from '@wefox-scheduling/shared/hooks';

import { useContactPageState } from '../contact-page.state';

import { ContactSuccessScreen } from './contact-success-screen.component';
import { successPageService } from './services/success-page.service';

export const ContactSuccessScreenController = (): JSX.Element => {
  const { broker, selectedTimeSlot, riskAnalysis } = useContactPageState();
  const navigateAfterSuccess = useNavigateAfterSuccess(riskAnalysis);

  const [descTranslationOptions, setDescTranslationOptions] = useState<{ placeHolders: string[] }>({
    placeHolders: [''],
  });

  const handlerClick = (): void => {
    navigateAfterSuccess();
  };

  useEffect(() => {
    setDescTranslationOptions(successPageService.createTranslationForPreference(selectedTimeSlot, ''));
  }, [selectedTimeSlot, setDescTranslationOptions]);

  return (
    <ContactSuccessScreen
      hasBroker={!!broker}
      descTranslationOptions={descTranslationOptions}
      handlerCTAClick={handlerClick}
    />
  );
};
