import React, { useEffect } from 'react';

import { useGetBrokerAndRiskAnalysis, useGetSelectedProducts } from '@wefox-scheduling/shared/hooks';

import { useContactPageState } from '../contact-page.state';

import { BrokerInformation } from './broker-information.component';
import { brokerService } from './services/broker.service';

export const BrokerInformationController = (): JSX.Element => {
  const { broker, riskAnalysis } = useGetBrokerAndRiskAnalysis();
  const selectedProducts = useGetSelectedProducts(riskAnalysis?.product_recommendations);
  const { dispatch } = useContactPageState();

  useEffect(() => {
    if (broker) {
      dispatch({ type: 'set-broker', payload: broker });
    }
    if (riskAnalysis) {
      dispatch({ type: 'set-risk-analysis', payload: { ...riskAnalysis, selected_products: selectedProducts } });
    }
  }, [broker, riskAnalysis, dispatch, selectedProducts]);

  return <BrokerInformation brokerTitle={brokerService.getBrokerTitle(broker)} />;
};
