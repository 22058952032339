import React from 'react';

import { i18n } from '@wefox/i18n';

import { BottomBar } from '@wefox-scheduling/shared/components';
import { useSetToolbar } from '@wefox-scheduling/shared/hooks';
import { backNavigationConfig } from '@wefox-scheduling/shared/utils';

import { CalendarSectionController } from '../calendar-section/calendar-section.controller';
import { TimeSlotsSectionController } from '../time-slots-section/time-slots-section.controller';

interface ConsultationScreenMobileProps {
  isContinueButtonDisabled: boolean;
  onContinueClick: () => void;
  onBackClick: () => void;
}

export const ConsultationScreenMobile = ({
  isContinueButtonDisabled,
  onContinueClick,
  onBackClick,
}: ConsultationScreenMobileProps): JSX.Element => {
  useSetToolbar(backNavigationConfig('RA_RISK_CONSULTATION'), { backNavigationClick: onBackClick });

  return (
    <>
      <div className="u-d-flex u-flex-column">
        <div className="u-mt-10">
          <CalendarSectionController />
        </div>
        <div className="u-mb-10 u-pt-8">
          <TimeSlotsSectionController />
        </div>
      </div>
      <BottomBar>
        <asteroid-button
          kind="primary"
          size="small"
          type="submit"
          onClick={onContinueClick}
          disabled={isContinueButtonDisabled}
        >
          {i18n.getTranslation('GLOBAL_continue')}
        </asteroid-button>
      </BottomBar>
    </>
  );
};
