import { i18n } from '@wefox/i18n';

import { TimeSlotModel } from '../../time-slots-section/model/time-slot.model';

export function getDateHourFromTimeSlot(originalDate: Date, hourString: string): string {
  const match = hourString.match(/^(\d{1,2})\s*(am|pm)$/i);
  const date = new Date(originalDate);

  if (!match) {
    throw new Error('Invalid time format');
  }

  let parsedHour = parseInt(match[1], 10);
  const ampm = match[2].toLowerCase();

  if (ampm === 'pm' && parsedHour !== 12) {
    parsedHour += 12;
  }

  date.setHours(parsedHour);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minutes}`;
}

export function formatDateAndTime(date: Date, time: TimeSlotModel): string {
  const day = date.getDate();
  const month = date.toLocaleDateString(i18n.getCurrentLocale(), { month: 'long' });

  return `${month} ${day}, ${time.displayedValue}`;
}
