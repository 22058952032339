import React from 'react';

import { i18n } from '@wefox/i18n';

import { PageFeedback } from '@wefox-scheduling/shared/components';
import { useSetToolbar } from '@wefox-scheduling/shared/hooks';
import { backNavigationConfig } from '@wefox-scheduling/shared/utils';

import { useContactPageStateUI } from '../contact-page.state-ui';

export const ContactLoadingScreen = (): JSX.Element => {
  const { dispatch: dispatchToContactPageStateUI } = useContactPageStateUI();
  const backNavigationClick = (): void => {
    dispatchToContactPageStateUI({ type: 'set-current-view', payload: 'contact' });
  };

  useSetToolbar(backNavigationConfig('RA_CONTACT_PREFERENCES_TITLE'), { backNavigationClick });

  return (
    <PageFeedback
      type="loading"
      title={i18n.getTranslation('RA_contact_preferences_error_title')}
      description={i18n.getTranslation('RA_contact_preferences_error_description')}
      primaryCtaLabel={''}
    />
  );
};
