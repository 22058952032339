import React, { useEffect, useRef } from 'react';

interface Props {
  type: string;
  title: string;
  primaryCtaLabel?: string;
  description?: string;
  onPrimaryCtaClick?: () => void;
}

export const Feedback = ({
  type,
  description,
  primaryCtaLabel,
  onPrimaryCtaClick,
  title,
}: Props): JSX.Element | null => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const asteroidFeedBack = ref.current;

    asteroidFeedBack?.addEventListener('feedbackPrimaryCtaClick', onPrimaryCtaClick as EventListener);

    return () => {
      asteroidFeedBack?.removeEventListener('feedbackPrimaryCtaClick', onPrimaryCtaClick as EventListener);
    };
  }, [onPrimaryCtaClick]);

  return (
    <asteroid-feedback
      ref={ref}
      data-testid={`feedback-${type}`}
      type={type}
      title={title}
      description={description}
      primary-cta-label={primaryCtaLabel}
    ></asteroid-feedback>
  );
};
