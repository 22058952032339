import React from 'react';

import { i18n } from '@wefox/i18n';

import { BottomBar } from '@wefox-scheduling/shared/components';

interface Props {
  isSubmitting: boolean;
  formId: string;
}

export const FormActionMobile = ({ isSubmitting, formId }: Props): JSX.Element => {
  return (
    <BottomBar>
      <asteroid-button disabled={isSubmitting} kind="primary" type="submit" form={formId}>
        <span>
          {isSubmitting && <asteroid-loader display="inline" size="small"></asteroid-loader>}
          {i18n.getTranslation('RA_GET_ADVICE_field_button_submit')}
        </span>
      </asteroid-button>
    </BottomBar>
  );
};
