import React from 'react';

import { usePageState } from '../calendar-page.state';

import { useGetAvailableTimeSlots } from './hooks/use-get-available-time-slots.hook';
import { TimeSlotModel } from './model/time-slot.model';
import { TimeSlotSection } from './time-slots-section.component';

export const TimeSlotsSectionController = (): JSX.Element | null => {
  const { selectedDate, dispatch } = usePageState();
  const { timeSlots, error, loading } = useGetAvailableTimeSlots();

  const handleTimeSlotSelection = (selectedTimeSlot: TimeSlotModel): void => {
    dispatch({ type: 'set-selected-time-slot', payload: selectedTimeSlot });
  };

  return (
    <TimeSlotSection
      onTimeSlotSelection={handleTimeSlotSelection}
      selectedDate={selectedDate}
      error={error}
      loading={loading}
      timeSlots={timeSlots}
    />
  );
};
