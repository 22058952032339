import cn from 'classnames';
import { Field } from 'formik';
import React from 'react';

import { i18n } from '@wefox/i18n';

import { SelectionBox } from '@wefox-scheduling/shared/components';

import { PREFERRED_APPOINTMENTS } from '../consts/preferred-appointments.const';
import { FormFieldProperties } from '../interfaces/form-field.interface';

export const SelectionBoxOptionsField = ({ id, errors }: FormFieldProperties): JSX.Element => {
  return (
    <div className={cn('form-group', 'u-my-6')}>
      <label className="form-label" htmlFor={id}>
        {i18n.getTranslation(`RA_GET_ADVICE_field_${id}`)}
      </label>
      {PREFERRED_APPOINTMENTS.map((appointment, appointmentIndex) => (
        <Field
          isLastElement={appointmentIndex === PREFERRED_APPOINTMENTS.length - 1}
          name={id}
          key={appointmentIndex}
          title={appointment.title}
          subtitle={appointment.subtitle}
          state={errors[id] ? 'error' : null}
          value={i18n.getTranslation(appointment.subtitle.key) || appointment.subtitle.fallback}
          component={SelectionBox}
          props={{ type: 'checkbox' }}
        />
      ))}
      {errors && <div className="invalid-feedback u-d-block">{i18n.getTranslation(errors[id] as string)}</div>}
    </div>
  );
};
